import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/pc',
    name: 'Index',
    component: () => import('@/views/Index.vue')
  },
  {
    path: '/mb',
    name: 'MobileIndex',
    component: () => import('@/views/MobileIndex.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      next({ path: '/mb' })
    } else {
      next({ path: '/pc' })
    }
  } else {
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      if (to.path.indexOf('/pc') !== -1) {
        next({ path: to.path.replace('/pc', '/mb') })
      } else {
        next()
      }
    } else {
      if (to.path.indexOf('/mb') !== -1) {
        next({ path: to.path.replace('/mb', '/pc') })
      } else {
        next()
      }
    }
  }
})

export default router
